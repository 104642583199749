@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "react-paginate/dist/react-paginate.css"; */


*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
/* Add this to your stylesheet */
li.active {
  background-color: #2d3748; 
  position: relative;
}
li.active::after{
  position: absolute;
  width: 4px;
  height: 100%;
  left: 0;
  background:greenyellow;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
/* Remove spin buttons and icons for WebKit-based browsers (Chrome, Safari, etc.) */
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    
}

/* For Firefox, remove arrow buttons */
input[type="time"] {
    -moz-appearance: textfield;
    appearance: none;
}

/* Remove default padding/spacing to hide any extra markings */
input[type="time"] {
    padding-left: 0;  /* Adjust padding as needed */
}



.pagination{
  display: flex;
  gap:10px;
}
.switch-case-container {
  width: 100%;
  padding: 2px;
  /* background-color: #ffffff;
  border-radius: 8px; */
}

.switch-case-buttons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap; /* Allows buttons to wrap to the next line */
  justify-content: center; /* Centers buttons */
}

.switch-case-button {
  cursor: pointer;
  padding: 2px 10px;
  margin-right: 4px;
  background-color: #d1d5db;
  border-radius: 4px;
  font-size: 14px;
  color: black;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevents text from wrapping */
}

.switch-case-button:hover {
  background-color: #777777;
}

@media (max-width: 1024px) {
  .switch-case-button {
    font-size: 12px; /* Smaller font size on medium devices */
    padding: 2px 8px; /* Smaller padding for medium devices */
  }

  .switch-case-buttons {
    justify-content: flex-start; /* Align buttons to the left on medium devices */
  }
}

@media (max-width: 768px) {
  .switch-case-button {
    font-size: 11px; /* Even smaller font size for smaller screens */
    padding: 2px 6px; /* Further reduce padding */
  }

  .switch-case-buttons {
    justify-content: flex-start; /* Align buttons to the left on smaller screens */
  }
}

@media (max-width: 480px) {
  .switch-case-button {
    font-size: 10px; /* Reduce font size for very small screens */
    padding: 2px 4px; /* Further reduce padding */
  }

  .switch-case-buttons {
    justify-content: center; /* Center buttons on small screens */
  }
}


.switch-case-button.active {
  background-color: #000; /* Change to your desired active color */
  color: #fff;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
